export const expenseCategoryKeywords = {
    Food: ['supermarket', 'restaurant', 'food', 'dinner', 'lunch', 'groceries'],
    Transportation: ['taxi', 'bus', 'metro', 'train', 'bike', 'uber', 'lyft'],
    Housing: ['rent', 'mortgage', 'electricity', 'water', 'internet', 'utilities'],
    Entertainment: ['cinema', 'theater', 'concert', 'book', 'videogames', 'movies'],
    Health: ['pharmacy', 'medicine', 'doctor', 'hospital', 'clinic', 'gym'],
    Education: ['tuition', 'courses', 'books', 'training'],
    Clothing: ['clothes', 'shoes', 'apparel', 'uniform'],
    'Gifts and Donations': ['gift', 'donation', 'charity', 'present'],
    Travel: ['flight', 'hotel', 'car rental', 'travel', 'vacation'],
    'Other Expenses': ['miscellaneous', 'other expenses', 'other'],
};

export const incomeCategoriesKeywords = {
    Salary: ['salary', 'paycheck', 'wage'],
    Freelance: ['freelance', 'contract', 'consulting'],
    'Interest and Dividends': ['interest', 'dividend', 'investment income'],
    Gifts: ['gift', 'donation', 'present', 'inheritance'],
    Sales: ['sale', 'commission', 'retail', 'revenue'],
    'Other Income': ['side hustle', 'business', 'other income', 'income'],
    Rental: ['rent', 'lease', 'property income'],
    'Royalties': ['royalty', 'licensing', 'patent'],
    'Bonuses and Incentives': ['bonus', 'incentive', 'reward'],
    'Pension': ['pension', 'retirement fund', 'annuity'],
};
